
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/listado/[type]",
      function () {
        return require("private-next-pages/listado/[type].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/listado/[type]"])
      });
    }
  