import styled from "@emotion/styled";
import { useTranslations } from "next-intl";
import Link from "next/link";
import { statusListType } from "pages/listado/[type]";
import React from "react";
import Typography from "shared/components/Typography";

interface IListTypeToggle {
  active: statusListType;
  handleToggle: (type: statusListType) => void;
}
function ListTypeToggle(props: IListTypeToggle) {
  const { active, handleToggle } = props;
  const translation = useTranslations("listAgentAgency.header");
  return (
    <ContainerSearchOptions>
      <Link href="agencias" shallow={true} passHref>
        <Typography
          component={`${active == 1 ? "h1" : "a"}`}
          className={`title ${active == 1 ? "active" : ""}`}
          onClick={() => handleToggle(1)}
        >
          {translation("agencyTitle")}
        </Typography>
      </Link>
      <Link href="agentes" shallow={true} passHref>
        <Typography
          component={`${active == 2 ? "h1" : "a"}`}
          className={`title  --left ${active == 2 ? "active" : ""}`}
          onClick={() => handleToggle(2)}
        >
          {translation("agentTitle")}
        </Typography>
      </Link>
    </ContainerSearchOptions>
  );
}

export default ListTypeToggle;

const ContainerSearchOptions = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  position: relative;
  padding: 0px 30px;
  height: 28px;
  @media screen and (max-width: 475px) {
    padding: 0px 20px;
  }

  & .title {
    padding: 6px 38px;
    background: red;
    font-size: 0.75rem;
    font-weight: 600;
    color: white;
    border-radius: 10px 10px 0px 0px;
    position: absolute;
    z-index: 9;
    top: 0;
    transition: 0.5s;
    @media screen and (max-width: 475px) {
      padding: 6px 34px;
    }

    &.--left {
      left: 160px;
      @media screen and (max-width: 475px) {
        left: 140px;
      }
    }
    &.active {
      background: var(--color-blue-main);
      z-index: 10;

      transform: scale(1.1);
      transition: 0.5s;
    }
  }
`;
